import React, { FC, memo } from "react";
import Logotype from "../../assets/logotype.svg";
import Security from "../../assets/security.svg";
import Editor from "../../assets/editor.svg";
import Designer from "../../assets/designer.svg";
import Settings from "../../assets/settings.svg";
import {
  SAdvantage,
  SAdvantageLogo,
  SAdvantagesList,
  SContainer,
  // SDemoLink,
  SGotoDoctrixSiteLink,
  SGrantText,
  SInnerContainer,
  SLogotype,
  SModuleCard,
  SModuleCardDescription,
  SModuleCardHeader,
  SModuleCardLogotype,
  SModuleCardSubTitle,
  SModuleCardTitle,
  SModuleContent,
  SModulesHeader,
  SModulesList,
  SModulesSection,
  STopSection,
  STopSectionLinks,
  STopSectionText,
} from "./style";
import { IProps } from "./types";

const MainScreenImpl: FC<IProps> = (props) => {
  const { modulesList } = props;

  return (
    <SContainer>
      <SInnerContainer>
        <STopSection id="header">
          <SLogotype src={Logotype} alt="Doctrix DPA logotype" />
          <STopSectionText>
            Поможем автоматизировать бизнес, <br />
            используя все виды инструментов проектирования
          </STopSectionText>
          <SAdvantagesList>
            <SAdvantage>
              <SAdvantageLogo src={Settings} alt="Settings icon" />
              <span>конструктор процессов</span>
            </SAdvantage>
            <SAdvantage>
              <SAdvantageLogo src={Editor} alt="Editor icon" />
              <span>редактор полей и переменных</span>
            </SAdvantage>
            <SAdvantage>
              <SAdvantageLogo src={Designer} alt="Designer icon" />
              <span>дизайнер форм</span>
            </SAdvantage>
            <SAdvantage>
              <SAdvantageLogo src={Security} alt="Security icon" />
              <span>менеджер доступа</span>
            </SAdvantage>
          </SAdvantagesList>
          <STopSectionLinks>
            <SGotoDoctrixSiteLink
              href="https://doctrix.ru/"
              target="_blank"
              rel="noreferrer"
            >
              перейти на сайт doctrix
            </SGotoDoctrixSiteLink>
            {/* <SDemoLink href="#">демо-версия</SDemoLink> */}
          </STopSectionLinks>
        </STopSection>
        <SModulesSection id="modules">
          <SModulesHeader>Документация</SModulesHeader>
          <SModulesList>
            {modulesList.map((module) => (
              <li key={module.name}>
                <SModuleCard
                  to={
                    module.children && module.children.length > 0
                      ? module.children[module.children.length - 1].basePath
                      : module.basePath
                  }
                  $borderColor={module.accentColor}
                >
                  <SModuleCardHeader>
                    <SModuleCardLogotype
                      src={`/docs/_media${module.basePath}/logotype.svg`}
                      alt={`${module.name} logotype`}
                    />
                    <SModuleCardTitle>{module.name}</SModuleCardTitle>
                  </SModuleCardHeader>
                  <SModuleContent>
                    <SModuleCardSubTitle>{module.name}</SModuleCardSubTitle>
                    <SModuleCardDescription>
                      {module.description}
                    </SModuleCardDescription>
                  </SModuleContent>
                </SModuleCard>
              </li>
            ))}
          </SModulesList>
          <SGrantText>
            Проект реализован при финансовой поддержке Фонда содействия инновациям
          </SGrantText>
        </SModulesSection>
      </SInnerContainer>
    </SContainer>
  );
};

export const MainScreen = memo(MainScreenImpl);
