import * as React from "react";
import { IProps } from "./types";
import { SCompoundButton, SIcon } from "./style";

export const CompoundButtonComponent: React.FC<IProps> = (props: IProps) => {
  const {
    isDisabled = false,
    isChecked = false,
    isPrimary = false,
    accentColor,
    onClick,
    source,
    label,
  } = props;

  return (
    <SCompoundButton
      onClick={onClick}
      primary={isPrimary}
      disabled={isDisabled}
      checked={isChecked}
      $accentColor={accentColor}
    >
      <span>{label}</span>
      <SIcon src={source} alt={`${label} icon`} />
    </SCompoundButton>
  );
};
