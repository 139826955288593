import React, { FC, memo } from "react";
import {
  SAboutContainer,
  SAboutTexts,
  SCellPhoneIcon,
  SCellPhoneLink,
  SCompanyText,
  SContactsContainer,
  SContainer,
  SContentContainer,
  SCopyrightDocument,
  SCopyrightDocumentText,
  SCopyrightLink,
  SCopyrightText,
  SCopyrightsContainer,
  SDecription,
  SEmailIcon,
  SEmailLink,
  SFooterDescription,
  SLogo,
  SLogotype,
  SOuterContainer,
} from "./style";
import Logotype from "../../assets/logotype.svg";
import Youtube from "../../assets/youtube.svg";
import { Icon } from "@fluentui/react";

const FooterImpl: FC = () => {
  return (
    <SOuterContainer>
      <SContainer>
        <SAboutContainer>
          <SLogotype src={Logotype} alt="Doctrix DPA logotype" />
          <SAboutTexts>
            <SDecription>
              Облачная платформа для гибкой
              <br /> автоматизации бизнес-процессов
            </SDecription>
            <a
              href="https://www.youtube.com/@iSysLabs"
              target="_blank"
              rel="noreferrer"
            >
              <SLogo src={Youtube} alt="Youtube logo" />
            </a>
          </SAboutTexts>
        </SAboutContainer>
        <SContentContainer>
          <SContactsContainer>
            <SCompanyText>
              Команда DPA DocTrix Platform ответит на все ваши вопросы по
              телефону и почте:
            </SCompanyText>
            <SCellPhoneLink href="tel:88005004317">
              <SCellPhoneIcon>✆</SCellPhoneIcon> 8 800 500-43-17
            </SCellPhoneLink>
            <SCellPhoneLink href="tel:+78463411411">
              <SCellPhoneIcon>✆</SCellPhoneIcon> +7 (846) 3-411-411
            </SCellPhoneLink>
            <SEmailLink href="mailto:sales@doctrix.ru">
              <SEmailIcon>
                <Icon iconName="Mail" />
              </SEmailIcon>
              sales@doctrix.ru
            </SEmailLink>
          </SContactsContainer>
          <SCopyrightsContainer>
            <SCopyrightText>
              © OOO «Ай-Сис Лабс» Россия, 2022. <br /> Права на торговые марки
              принадлежат правообладателю.
            </SCopyrightText>
            <SCopyrightDocument>
              <SCopyrightDocumentText>
                Заявление о конфиденциальности
              </SCopyrightDocumentText>
              <SCopyrightLink
                href="https://doctrix.ru/Policy_Doctrix.pdf"
                target="_blank"
                rel="noreferrer"
              >
                DocTrix Mobile
              </SCopyrightLink>
            </SCopyrightDocument>
          </SCopyrightsContainer>
        </SContentContainer>
        <SFooterDescription>
          Программа для ЭВМ «DocTrix Platform» включена в Единый реестр
          российских программ для ЭВМ и баз данных <br />
          на основании Приказа Минкомсвязи России от 08.11.2016 г. №538,
          реестровый № 2245
        </SFooterDescription>
      </SContainer>
    </SOuterContainer>
  );
};

export const Footer = memo(FooterImpl);
