import { GlobalStyle } from "./styles";
import { Template } from "./pages";
import { RouterContext, BreadcrumbsContext } from "./contexts";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter } from "react-router-dom";
import { BASE_PATH_ROUTE } from "./constants";

import "react-perfect-scrollbar/dist/css/styles.css";

import "./fonts/Inter/Inter-Black.ttf";
import "./fonts/Inter/Inter-Bold.ttf";
import "./fonts/Inter/Inter-ExtraBold.ttf";
import "./fonts/Inter/Inter-ExtraLight.ttf";
import "./fonts/Inter/Inter-Light.ttf";
import "./fonts/Inter/Inter-Medium.ttf";
import "./fonts/Inter/Inter-Regular.ttf";
import "./fonts/Inter/Inter-SemiBold.ttf";
import "./fonts/Inter/Inter-Thin.ttf";

initializeIcons();

const Bootstrap = () => {
  return (
    <BrowserRouter basename={BASE_PATH_ROUTE}>
      <RouterContext.Provider>
        <BreadcrumbsContext.Provider>
          <>
            <GlobalStyle />
            <Template />
          </>
        </BreadcrumbsContext.Provider>
      </RouterContext.Provider>
    </BrowserRouter>
  );
};

export { Bootstrap };
