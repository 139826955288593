import { BREAKPOINTS } from "@/constants";
import styled from "styled-components";

export const SContainer = styled.div`
  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    overflow-y: auto;
    max-height: var(--app-height);
  }
`;

export const SSpinnerContainer = styled.div`
  display: flex;
  height: var(--app-height);
  width: 100%;
  align-items: center;
  justify-content: center;
`;
