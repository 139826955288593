import { FC, memo, useCallback, useState } from "react";
import { Props } from "./types";
import { SIcon, SButton, SContainer } from "./style";

const ExpanderImpl: FC<Props> = (props: Props) => {
  const { label, level, children, isCurrentSection = false, expanded: propsExpanded, onClick } = props;

  const [expanded, setExpanded] = useState(Boolean(propsExpanded));

  const toggleExpand = useCallback(() => {
    setExpanded((s) => !s);
    onClick?.()
  }, [onClick]);

  return (
    <SContainer>
      <SButton onClick={toggleExpand} $level={level} $currentSection={isCurrentSection && !expanded}>
        <SIcon $expanded={expanded} iconName={expanded ? "ChevronUp" : "ChevronDown"} />
        <span>{label}</span>
      </SButton>
      {expanded && children}
    </SContainer>
  );
};

export const Expander = memo(ExpanderImpl);
