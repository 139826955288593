import { business } from "@/types";

function escapeHtml(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');

  return doc.body.textContent || "";
}

function ignoreDiacriticalMarks(keyword: string) {
  if (keyword && keyword.normalize) {
    return keyword.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  return keyword;
}

export const searchMarkdownData = (
  data: business.INavigationElementDto[],
  searchQuery: string
): business.ISearchResult[] => {
  const matchingResults: business.ISearchResult[] = [];

  const query = searchQuery.trim();

  let keywords: string[] = query.split(/[\s\-，\\/]+/);

  if (keywords.length !== 1) {
    keywords = ([] as string[]).concat(query, keywords);
  }

  const loop = (index: number) => {
    const post = data[index];
    let matchesScore = 0;
    let resultStr = "";
    let handlePostTitle = "";
    let handlePostContent = "";
    const postTitle = post.name && post.name.trim();
    const postContent = post.mdData?.data ? post.mdData.data.trim() : "";
    const postUrl = post.path || "";

    if (postTitle) {
      keywords.forEach(function (keyword) {
        // From https://github.com/sindresorhus/escape-string-regexp
        const regEx = new RegExp(
          escapeHtml(ignoreDiacriticalMarks(keyword)).replace(
            /[|\\{}()[\]^$+*?.]/g,
            "\\$&"
          ),
          "gi"
        );
        let indexTitle = -1;
        let indexContent = -1;

        handlePostTitle = postTitle
          ? escapeHtml(ignoreDiacriticalMarks(postTitle))
          : postTitle;
        handlePostContent = postContent
          ? escapeHtml(ignoreDiacriticalMarks(postContent))
          : postContent;

          debugger

        indexTitle = postTitle ? handlePostTitle.search(regEx) : -1;
        indexContent = postContent ? handlePostContent.search(regEx) : -1;

        if (indexTitle >= 0 || indexContent >= 0) {
          matchesScore += indexTitle >= 0 ? 3 : indexContent >= 0 ? 2 : 0;
          if (indexContent < 0) {
            indexContent = 0;
          }

          let start = 0;
          let end = 0;

          start = indexContent < 11 ? 0 : indexContent - 10;
          end = start === 0 ? 70 : indexContent + keyword.length + 60;

          if (postContent && end > postContent.length) {
            end = postContent.length;
          }

          if (handlePostContent) {
            const matchContent =
              "..." +
              handlePostContent
                .substring(start, end)
                .replace(regEx, function (word) {
                  return '<em class="search-keyword">' + word + "</em>";
                }) +
              "...";

            resultStr += matchContent;
          }
        }
      });

      if (matchesScore > 0) {
        const matchingPost: business.ISearchResult = {
          title: handlePostTitle,
          content: postContent ? resultStr : "",
          url: postUrl,
          score: matchesScore,
          navigation: post,
        };

        matchingResults.push(matchingPost);
      }
    }
  };

  for (let i = 0; i < data.length; i++) loop(i);

  return matchingResults.sort(function (r1, r2) {
    return r2.score - r1.score;
  });
};
