import { BREAKPOINTS, COLORS } from "@/constants";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { slide as Menu } from "react-burger-menu";

export const SOuterContainer = styled.div<{ $isBurgerOpened: boolean }>`
  background-color: ${COLORS.white};
  display: grid;
  grid-template-columns: 424px calc(100% - 424px - 286px) 286px;
  grid-template-rows: min-content auto;
  grid-template-areas: "nav breadcrumbs version" "nav content version";
  height: 100%;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    align-content: start;
    overflow-y: visible;
    grid-template-columns: 100%;
    grid-template-areas: "logotype" "breadcrumbs" "nav" "version" "burger" "content";
    max-width: ${BREAKPOINTS.mobileL};
    height: 100%;

    ${({ $isBurgerOpened }) => ($isBurgerOpened ? "overflow: hidden;" : "")}

    div[class*='scrollbar-container'] {
      > ul {
        display: none;
      }
    }
  }
`;

export const SScrollableContainer = styled(PerfectScrollbar)`
  overflow-y: auto;
  padding: 0 40px 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-area: content;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    grid-area: content;
    height: max-content;
    padding: 0 1rem;
  }
`;

export const SScrollableContainerMobile = styled.div`
  padding: 30px 40px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-area: content;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    padding: 0 1rem;
    overflow-y: visible;
  }
`;

export const SSpinnerContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SBurgerButton = styled.button`
  display: none;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    grid-area: burger;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    font-size: 1rem;
    padding: 1.5rem 1rem 0 1rem;
    background: transparent;
    i[data-icon-name="NumberedListText"] {
      font-size: 28px;
      margin-right: 6px;
    }
  }
`;

export const SMenu = styled(Menu)`
  display: none;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    display: block;
  }
`;
