import React, { createContext, FC, ReactNode } from "react";
import { DEFAULT_STATE } from "./constants";
import { StateType } from "./types";
import {
  builtBreadcrumbsItems,
  findModuleInnerPath,
  findModulePath,
} from "./utils";
import { business } from "@/types";

const nullFunction = (...args: any[]): void => {
  // eslint-disable-next-line no-console
  return;
};

const StateContext = createContext<StateType>(DEFAULT_STATE);
const DispatchContext = createContext({
  updateHistory: nullFunction,
  updateBreadcrumbsData: nullFunction,
});

type Props = {
  children: ReactNode;
};

const Provider: FC<Props> = ({ children }: Props) => {
  const [state, setState] = React.useState<StateType>(DEFAULT_STATE);

  const updateHistory = (searchPath: string) => {
    const module = findModulePath({
      root: state.modules,
      searchPath,
    });

    const moduleInnerNavigation = findModuleInnerPath({
      root: state.navigation,
      searchPath,
    });

    setState((prevState) => ({
      ...prevState,
      items: builtBreadcrumbsItems({
        moduleInnerNavigation,
        module,
        root: true,
      }),
    }));
  };

  const updateBreadcrumbsData = ({
    navigation,
    modules,
  }: {
    navigation: business.INavigationElementDto[];
    modules: business.IModuleDto[];
  }) => {
    setState((prevState) => ({
      ...prevState,
      navigation,
      modules,
    }));
  };

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider
        value={{ updateHistory, updateBreadcrumbsData }}
      >
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export { StateContext, Provider, DispatchContext };
