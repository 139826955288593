import { BREAKPOINTS, COLORS } from "@/constants";
import styled from "styled-components";

export const SOuterContainer = styled.div`
  width: 100%;
  background: ${COLORS.backgroundDoctrix};
`;

export const SContainer = styled.div`
  width: var(--container-width);
  display: grid;
  grid-template-columns: 320px auto;
  grid-template-rows: auto min-content;
  grid-template-areas: "about content content" "description description description";
  padding: 3rem 0;
  height: 326px;
  margin: 0 auto;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 3rem 1rem;
    height: fit-content;

    & > * + * {
      margin-top: 2rem !important;
    }
  }
`;

export const SContentContainer = styled.div`
  margin-left: 140px;
  grid-area: content;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1.5rem / 2));
  justify-content: space-between;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;

    & > * + * {
      margin-top: 2rem;
    }
  }
`;

export const SAboutContainer = styled.div`
  grid-area: about;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    width: 100%;
  }
`;

export const SAboutTexts = styled.div`
  padding-left: 3rem;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1.25rem;
  }
`;

export const SLogotype = styled.img`
  height: 40px;
  width: auto;
  margin-right: auto;
`;

export const SDecription = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
`;

export const SLogo = styled.img`
  height: 1.25rem;
  width: auto;
`;

export const SContactsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.75rem;
  }
`;

export const SCompanyText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: #9a9a9a;
`;

export const SCellPhoneLink = styled.a`
  font-size: 1rem;
  font-weight: 400;
  padding-left: 2rem;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.darkDoctrix};
`;

export const SCellPhoneIcon = styled.span`
  font-size: 2rem;
  line-height: 0;
  position: absolute;
  top: 50%;
  left 0;
  transform: translateY(-50%);
`;

export const SEmailLink = styled.a`
  font-size: 1rem;
  font-weight: 400;
  padding-left: 1.7rem;
  position: relative;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: #0078d4;
`;

export const SEmailIcon = styled.span`
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  left 0;
  transform: translateY(-50%);
  color: ${COLORS.darkDoctrix};

  & > i {
    line-height: 1.7;
  }
`;

export const SCopyrightsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 2rem;
  }
`;

export const SCopyrightText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  color: #9a9a9a;
`;

export const SCopyrightDocument = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SCopyrightDocumentText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${COLORS.darkDoctrix};
`;

export const SCopyrightLink = styled.a`
  font-size: 1rem;
  font-weight: 400;
  color: #0078d4;
`;

export const SFooterDescription = styled.span`
  margin: 3rem auto 0;
  grid-area: description;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: center;
  color: #d6d6d6;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
  }
`;
