import React, { FC, useRef, useEffect, useCallback, useState } from "react";
import {
  SContainer,
  SLogotype,
  SModulesList,
  SNavigation,
  SNavigationSection,
  SNavLink,
  SScrollableMainContent,
  SSearchContainer,
  SSearchInnerContainer,
  SSearchNavLink,
  SSearchResultsContainer,
  SSectionTitle,
} from "./style";
import { IProps } from "./types";
import Logotype from "../../assets/logotype.svg";
import { CompoundButtonComponent, Expander } from "@/components";
import { useLocation, useNavigate } from "react-router";
import { Icon, SearchBox, Spinner, SpinnerSize } from "@fluentui/react";
import { business } from "@/types";
import { useDebounceValue } from "@/hooks";
import { searchMarkdownData } from "@/utils";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";

const NavigationBar: FC<IProps> = (props: IProps) => {
  const {
    modulesList,
    navigation,
    isUpdating,
    onClick,
    asBurger,
    selectedModule,
  } = props;

  const scrollableContainerRef = useRef<any>();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [searchResults, setSearchResults] = useState<business.ISearchResult[]>(
    []
  );

  const [modulesContainerHeight, setModulesContainerHeight] = useState(124);

  const handleSearch = useCallback(
    (searchQuery: string) => {
      setSearchCompleted(false);
      try {
        const markdowns: business.INavigationElementDto[] = [];

        const buildMarkdowns = (
          navigations: business.INavigationElementDto[]
        ) => {
          navigations.forEach((nav) => {
            if (nav.children) {
              buildMarkdowns(nav.children);
            } else if (nav.mdData) {
              markdowns.push(nav);
            }
          });
        };

        buildMarkdowns(navigation);

        const results = searchMarkdownData(markdowns, searchQuery);

        setSearchResults(results);
        setSearchCompleted(true);
      } catch (e) {
        console.log(e);
        setSearchResults([]);
        setSearchCompleted(false);
      }
    },
    [navigation]
  );

  const debounceSearchInput = useDebounceValue(searchInput, 500);

  const recalculateScroll = useCallback(() => {
    scrollableContainerRef.current?.updateScroll();
  }, []);

  const getExpandedSection = useCallback(
    (nav: business.INavigationElementDto, level = 0) => {
      return (
        <Expander
          onClick={recalculateScroll}
          label={nav.name}
          isCurrentSection={location.pathname.startsWith(nav.basePath)}
          expanded={location.pathname.startsWith(nav.basePath)}
          level={level}
        >
          <SNavigationSection $level={level}>
            {(nav.children || []).length > 0
              ? nav.children.map((innerNav) => (
                  <li key={`${innerNav.path || innerNav.basePath}-${level}`}>
                    {innerNav.children ? (
                      getExpandedSection(innerNav, level + 1)
                    ) : (
                      <SNavLink
                        $hasIcon={Boolean(nav.iconName)}
                        $level={level}
                        $active={location.pathname === innerNav.path}
                        to={innerNav.path || ""}
                        onClick={onClick}
                      >
                        {innerNav.iconName && level < 1 && (
                          <Icon iconName={innerNav.iconName} />
                        )}
                        <span>{innerNav.name}</span>
                      </SNavLink>
                    )}
                  </li>
                ))
              : null}
          </SNavigationSection>
        </Expander>
      );
    },
    [location.pathname, onClick, recalculateScroll]
  );

  const setDialogRef = useCallback((ref: HTMLUListElement) => {
    const modulesRect = ref?.getBoundingClientRect();

    if (modulesRect) {
      setModulesContainerHeight(modulesRect.height);
    }
  }, []);

  useEffect(() => {
    if (debounceSearchInput) {
      handleSearch(debounceSearchInput);
    } else {
      setSearchResults([]);
      setSearchCompleted(false);
    }
  }, [handleSearch, debounceSearchInput]);

  useEffect(() => {
    if (debounceSearchInput) {
      setSearchInput("");
      setSearchResults([]);
      setSearchCompleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule?.basePath]);

  return (
    <SContainer>
      {!asBurger && (
        <NavLink to="/">
          <SLogotype src={Logotype} alt="Doctrix DPA logotype" />
        </NavLink>
      )}
      <SModulesList ref={setDialogRef} $asBurger={asBurger}>
        {modulesList.length > 0
          ? modulesList.map((md) => (
              <li key={md.basePath}>
                <CompoundButtonComponent
                  accentColor={md.accentColor}
                  label={md.name}
                  onClick={() => navigate(md.defaultPath || md.basePath)}
                  source={`/docs/_media${md.basePath}/icon${
                    location.pathname.startsWith(md.basePath) ? "_accent" : ""
                  }.svg`}
                  isPrimary={location.pathname.startsWith(md.basePath)}
                />
              </li>
            ))
          : null}
      </SModulesList>
      {isUpdating ? (
        isMobile ? (
          <SSearchContainer $asBurger={asBurger}>
            <SearchBox
              value={searchInput}
              placeholder="Найти в документации"
              onChange={(_ev, newValue) => setSearchInput(newValue || "")}
            />
          </SSearchContainer>
        ) : (
          <Spinner size={SpinnerSize.large} />
        )
      ) : (
        <SScrollableMainContent
          ref={scrollableContainerRef}
          $modulesContainerHeight={modulesContainerHeight}
        >
          <SSearchContainer $asBurger={asBurger}>
            <SearchBox
              value={searchInput}
              placeholder="Найти в документации"
              onChange={(_ev, newValue) => setSearchInput(newValue || "")}
            />
            {searchInput.length > 0 && searchCompleted && (
              <SSearchInnerContainer>
                {searchResults.length > 0 ? (
                  <>
                    <SSectionTitle>Результаты поиска</SSectionTitle>
                    <SSearchResultsContainer>
                      {searchResults.map((nav, index) => (
                        <li
                          key={`${
                            nav.navigation.path || nav.navigation.basePath
                          }-${index}`}
                        >
                          <SSearchNavLink to={nav.navigation.path || "/"}>
                            <div
                              dangerouslySetInnerHTML={{ __html: nav.content }}
                            />
                          </SSearchNavLink>
                        </li>
                      ))}
                    </SSearchResultsContainer>
                  </>
                ) : (
                  <SSectionTitle>{`По запросу ${searchInput} ничего не найдено`}</SSectionTitle>
                )}
              </SSearchInnerContainer>
            )}
          </SSearchContainer>
          <SNavigation $asBurger={asBurger}>
            {navigation.length > 0
              ? navigation.map((nav, index) => (
                  <li key={`${nav.path || nav.basePath}-${index}`}>
                    {nav.children ? (
                      getExpandedSection(nav)
                    ) : (
                      <SNavLink
                        $hasIcon={Boolean(nav.iconName)}
                        $active={location.pathname === nav.path}
                        $level={0}
                        $mainRoot={true}
                        to={nav.path || "/"}
                        onClick={onClick}
                      >
                        {nav.iconName && <Icon iconName={nav.iconName} />}
                        <span>{nav.name}</span>
                      </SNavLink>
                    )}
                  </li>
                ))
              : null}
          </SNavigation>
        </SScrollableMainContent>
      )}
    </SContainer>
  );
};

export { NavigationBar };
