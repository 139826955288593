import React, { FC, memo, useEffect, useRef } from "react";
import { SNavigationLink } from "./style";

interface IProps {
  text: string;
  active: boolean;
  href: string;
}

const SectionLinkImpl: FC<IProps> = (props) => {
  const { text, active, href } = props;

  const linked = useRef(false)

  useEffect(() => {
    if (!linked.current && active) {
        linked.current = true;
        window.location.href = href;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  return (
    <SNavigationLink $active={active} href={href}>
      {text}
    </SNavigationLink>
  );
};

export const SectionLink = memo(SectionLinkImpl);
