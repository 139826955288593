import React, { useCallback, FC, memo } from "react";
import { Icon, IDropdownOption } from "@fluentui/react";
import { Props } from "./types";
import { SField, SDropdown, SClearButton } from "./style";

const DropdownComponentImpl: FC<Props> = (props: Props) => {
  const {
    value,
    valueKey,
    isClearAvailable,
    placeholder,
    options,
    isDisabled,
    errorMessage,
    onChange,
  } = props;

  const handleChange = useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption<any> | undefined
    ) => {
      if (option?.key) onChange(option.key, valueKey);
      else onChange(null);
    },
    [onChange, valueKey]
  );

  const handleClear = useCallback(() => {
    onChange(null, valueKey);
  }, [onChange, valueKey]);

  return (
    <SField>
      <SDropdown
        $isClearAvailable={isClearAvailable}
        placeholder={placeholder}
        selectedKey={value}
        errorMessage={errorMessage}
        options={options}
        onChange={handleChange}
        disabled={isDisabled}
      />
      {(isClearAvailable && value && value !== "all") && (
        <SClearButton onClick={handleClear}>
          <Icon iconName="Clear" />
        </SClearButton>
      )}
    </SField>
  );
};

export const DropdownComponent = memo(DropdownComponentImpl);
