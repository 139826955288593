import styled from "styled-components";
import { Icon } from "@fluentui/react";
import { COLORS } from "@/constants";

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;

export const SButton = styled.button<{
  $level: number;
  $currentSection: boolean;
}>`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  padding: ${({ $level }) =>
    $level === 0 ? "10px 0 0 0" : `10px 0 0 ${$level * 24}px`};
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;

  & > span {
    font-weight: 400;
    text-align: left;
    font-size: ${({ $level }) => {
      if ($level === 0) return "20px";

      return "16px";
    }};
    margin-left: 10px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: ${({ $currentSection }) =>
      $currentSection ? COLORS.accent : COLORS.darkDoctrix};
  }
`;

export const SIcon = styled(Icon)<{ $expanded: boolean }>`
  transform: ${({ $expanded }) => ($expanded ? "rotate(180deg)" : "rotate(-90deg)")};
`;
