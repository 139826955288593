import React, { FC, useEffect, useState, useCallback, useMemo } from "react";
import {
  SContainer,
  SContextContainer,
  SNoNavigationText,
  SPageContextHeader,
  SScrollableSections,
  SSections,
} from "./style";
import { Dropdown } from "@/components";
import { IProps } from "./types";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { NAVIGATION_FILE_POSTFIX } from "@/constants";
import { business } from "@/types";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { SectionLink } from "./Link";

const SectionBar: FC<IProps> = (props) => {
  const { selectedModule, pageData, moduleVersion } = props;

  const [isLoadingAnchors, setIsLoadingAnchors] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const [contextNavigation, setContextNavigation] = useState<
    business.IContextNavigationElement[]
  >([]);

  const location = useLocation();

  const navigate = useNavigate();

  const currentPageMdPath = useMemo(
    () => (pageData ? pageData.mdPath || null : null),
    [pageData]
  );

  const moduleSelectOptions = useMemo(() => {
    return selectedModule?.children && selectedModule.children.length > 0
      ? selectedModule.children.map((md) => ({
          key: md.basePath,
          text: md.name,
        }))
      : [];
  }, [selectedModule]);

  const selectedValue = useMemo(() => {
    return moduleSelectOptions.find((option) =>
      location.pathname.startsWith(option.key)
    );
  }, [location, moduleSelectOptions]);

  const handleChange = useCallback(
    async (newValue: any) => {
      if (newValue && newValue !== location.pathname) {
        const currentPageMdPathWithoutVersionPath =
          moduleVersion && pageData?.mdPath
            ? pageData.mdPath.replace(`/docs${moduleVersion.basePath}`, "")
            : null;
        const currentPagePathWithoutVersionPath =
          moduleVersion && pageData?.path
            ? pageData.path.replace(moduleVersion.basePath, "")
            : null;

        if (
          currentPageMdPathWithoutVersionPath &&
          currentPagePathWithoutVersionPath
        ) {
          setIsChecking(true);
          try {
            await axios.get(
              `/docs${newValue}${currentPageMdPathWithoutVersionPath}`
            );

            navigate(`${newValue}${currentPagePathWithoutVersionPath}`);
          } catch (e) {
            console.log(e);
            navigate(newValue);
          }
          setIsChecking(false);
        } else {
          navigate(newValue);
        }
      }
    },
    [location.pathname, moduleVersion, navigate, pageData]
  );

  const renderedPageNavigation = useMemo(() => {
    return contextNavigation.length > 0 ? (
      <SScrollableSections>
        <SSections>
          {contextNavigation.map((cNav) => (
            <li key={cNav.anchor}>
              <SectionLink
                active={location.hash === cNav.anchor}
                href={cNav.anchor}
                text={cNav.text}
              />
            </li>
          ))}
        </SSections>
      </SScrollableSections>
    ) : (
      <SNoNavigationText>Нет разделов</SNoNavigationText>
    );
  }, [location, contextNavigation]);

  const loadContextNavigation = useCallback(async () => {
    if (!currentPageMdPath) {
      setContextNavigation([]);
      return;
    }

    setIsLoadingAnchors(true);

    try {
      const { data: newContextNavigation } = await axios.get(
        currentPageMdPath.replace(".md", NAVIGATION_FILE_POSTFIX)
      );

      setContextNavigation(newContextNavigation || []);
    } catch (e) {
      console.log(e);
      setContextNavigation([]);
    }

    setIsLoadingAnchors(false);
  }, [currentPageMdPath]);

  useEffect(() => {
    loadContextNavigation();
  }, [loadContextNavigation]);

  return (
    <SContainer>
      {selectedModule?.children && selectedModule.children.length > 0 ? (
        <Dropdown
          options={moduleSelectOptions}
          value={selectedValue?.key || null}
          onChange={handleChange}
          isDisabled={isChecking}
        />
      ) : null}
      <SContextContainer>
        <SPageContextHeader>На этой странице</SPageContextHeader>
        {isLoadingAnchors ? (
          <Spinner size={SpinnerSize.medium} />
        ) : (
          renderedPageNavigation
        )}
      </SContextContainer>
    </SContainer>
  );
};

export { SectionBar };
