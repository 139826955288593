import { COLORS, BREAKPOINTS } from "@/constants";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Black.ttf") format("truetype");
        font-weight: 900;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
        font-weight: 800;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
        font-weight: 700;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
        font-weight: 600;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Medium.ttf") format("truetype");
        font-weight: 500;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
        font-weight: 400;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Light.ttf") format("truetype");
        font-weight: 300;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
        font-weight: 200;
    }
    @font-face {
        font-family: "Inter";
        src: local("Inter"), url("./fonts/Inter/Inter-Thin.ttf") format("truetype");
        font-weight: 100;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--input-rest--text-value--color);
        -webkit-box-shadow: 0 0 0px 1000px var(--input-rest--bg--color) inset;
        background-clip: content-box !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    input[data-autocompleted] {
        background-color: transparent !important;
    }

    input:disabled,
    textarea:disabled,
    input:disabled::placeholder,
    textarea:disabled::placeholder,
    div[class*="ms-ComboBox is-disabled"] input,
    div[class*="ms-Checkbox is-disabled"] span,
    div[class*="ms-TextField is-disabled"] input {
      -webkit-text-fill-color: currentcolor;
      font-family: inherit;
    }

    .safari input:disabled,
    .safari textarea:disabled,
    .safari input:disabled::placeholder,
    .safari textarea:disabled::placeholder,
    .safari div[class*="ms-ComboBox is-disabled"] input,
    .safari div[class*="ms-Checkbox is-disabled"] span,
    .safari div[class*="ms-TextField is-disabled"] input {
        color: var(--app-font--color) !important;
        font-family: inherit;
    }

    input, textarea {
        font-family: inherit;
    }
    
    html,
    body {
        height: 100%;
        margin: 0;
        padding: 0;
        scroll-behavior: smooth;
        font-family: 'Inter', sans-serif;
    }

    #root {
        height: fit-content;
        min-height: 100%;
        width: var(--app-width);

        @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
            overflow-y: hidden;
            max-height: var(--app-height)
        }
    }

    :root {
        --container-width: 1360px;
        --app-width: 100%;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        background: #fff;
        color: #323130;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
  
    a {
        text-decoration: none;
        color: inherit;
    }
  
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    input,
    textarea,
    select {
        font-family: inherit;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    .search-keyword {
        color: ${COLORS.accentLight}
    }

    @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
        .bm-burger-button {
            position: fixed;
            width: 36px;
            height: 30px;
            left: 36px;
            top: 36px;
        }
        
        .bm-burger-bars {
            background: #373a47;
        }
        
        .bm-burger-bars-hover {
            background: #a90000;
        }
        
        .bm-cross-button {
            height: 24px;
            width: 24px;
            right: calc(50% - 1rem) !important;
            top: 2rem !important;
        }

        .bm-cross {
            background: transparent;
        }
        
        .bm-menu-wrap {
            position: fixed;
            height: 100%;
            width: 100% !important;
        }
        
        .bm-menu {
            background: rgb(255, 255, 255);
            font-size: 1.15em;
        }
        
        .bm-morph-shape {
            fill: #373a47;
        }
        
        .bm-item-list {
            color: #b8b7ad;
        }
        
        .bm-item {
            display: inline-block;
        }
        
        .bm-overlay {
            background: rgba(0, 0, 0, 0.3);
        }
    }
`;

export default GlobalStyle;
