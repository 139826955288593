import { business } from "@/types";
import { BreadcrumbItem } from "./types";

export const findModuleInnerPath = ({
  root,
  searchPath,
}: {
  root: business.INavigationElementDto[];
  searchPath: string;
}) => {
  let pathStack: business.INavigationElementDto[] = [];

  const findNavigationPath = (
    navigation: business.INavigationElementDto,
    searchPath: string,
    stack: business.INavigationElementDto[]
  ) => {
    let innerStack: business.INavigationElementDto[] = [];

    if (navigation.basePath === searchPath) {
      return stack;
    }

    const childrens = navigation.children ?? [];

    for (let i = 0; i < childrens.length; i++) {
      const node = childrens[i];

      innerStack.push(node);

      const result = findNavigationPath(node, searchPath, [...innerStack]);

      if (result) {
        stack.push(...result);
        return stack;
      } else {
        innerStack = [];
      }
    }
  };

  for (let i = 0; i < root.length; i++) {
    pathStack = [root[i]];
    const result = findNavigationPath(root[i], searchPath, pathStack);

    if (result) {
      break;
    } else {
      pathStack = [];
    }
  }
  return pathStack;
};

export const findModulePath = ({
  root,
  searchPath,
}: {
  root: business.IModuleDto[];
  searchPath: string;
}) => {
  const findNavigationPath = (
    modules: business.IModuleChildrenDto[],
    searchPath: string
  ) => {
    for (let i = 0; i < modules.length; i++) {
      const node = modules[i];

      if (searchPath.includes(node.basePath)) {
        return node;
      }
    }
  };

  for (let i = 0; i < root.length; i++) {
    const result = findNavigationPath(root[i].children || [], searchPath);

    if (result) {
      return result;
    }
  }

  return null;
};

export const builtBreadcrumbsItems = ({
  moduleInnerNavigation,
  module,
}: {
  moduleInnerNavigation: business.INavigationElementDto[];
  module: business.IModuleChildrenDto | null;
  root: boolean;
}): BreadcrumbItem[] => {
  const builtItems: BreadcrumbItem[] = [
    {
      displayName: "Главная",
      href: "/",
    },
  ];

  if (module) {
    builtItems.push({
      displayName: module.name,
      href: module.basePath,
    });
  }

  builtItems.push(
    ...moduleInnerNavigation.map((mdInnerNavigation) => ({
      displayName: mdInnerNavigation.name,
      href: mdInnerNavigation.basePath,
    }))
  );

  return builtItems;
};
