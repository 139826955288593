import { IconButton, Dropdown } from "@fluentui/react";
import styled from "styled-components";

export const SField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  & > * + * {
    margin-top: 5px;
  }
`;

export const SPasswordField = styled.div`
  position: relative;
`;

export const SHideButton = styled(IconButton)`
  position: absolute;
  color: #a19f9d;
  top: 0;
  right: 0;
  background: transparent !important;
  width: 40px;
  height: 100%;
`;

export const SDropdown = styled(Dropdown)<{ $isClearAvailable?: boolean }>`
  & span.ms-Dropdown-title {
    padding-right: ${({ $isClearAvailable }) =>
      $isClearAvailable ? "48px" : "28px"};
  }
`;

export const SClearButton = styled.div`
  position: absolute;
  top: 36px;
  right: 28px;
  transform: translateY(-50%);
  cursor: pointer;
  background: transparent;
  border: none;
  width: fit-content;
  height: fit-content;

  & i {
    font-size: 12px;
  }
`;
