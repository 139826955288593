import React, { FC, memo, useContext } from "react";
import ArrowRight from "../../assets/arrow-right.svg";
import { SArrowIcon, SContainer, SNavLink } from "./style";
import { BreadcrumbsContext } from "@/contexts";

const BreadcrumbsImpl: FC = () => {
  const { items } = useContext(BreadcrumbsContext.StateContext);

  return (
    <SContainer>
      {items.map((item, idx) => {
        const isLastElement = idx === items.length - 1;
        const isClickableElement = idx <= 1 || isLastElement;

        return (
          <SNavLink
            key={item.href}
            to={item.href}
            $active={isLastElement}
            $clickable={isClickableElement}
            onClick={
              !isClickableElement
                ? (e) => {
                    e.preventDefault();
                  }
                : undefined
            }
          >
            <span>{item.displayName}</span>
            {isLastElement ? null : <SArrowIcon src={ArrowRight} />}
          </SNavLink>
        );
      })}
    </SContainer>
  );
};

export const Breadcrumbs = memo(BreadcrumbsImpl);
