import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import { COLORS, COLORS_WITH_OPACITY, BREAKPOINTS } from "@/constants";

export const SSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    position: fixed;
    bottom: 30%;
    height: fit-content;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const SScrollableContainer = styled(PerfectScrollbar)`
  overflow-y: auto;
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  width: 100%;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: revert;
    padding: revert;
    line-height: 1.4;
  }

  blockquote {
    color: ${COLORS.darkDoctrix};
    font-size: inherit;
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    margin: 16px 0;
    border-left: 5px solid #eaeaea;
    padding-left: 50px;
    background-color: #f0f1f2;

    & p {
      margin: 0;
    }
  }

  .critical {
    border-left: 5px solid #c70a0a;

    & p {
      font-weight: 700;
    }
  }

  a {
    color: ${COLORS.accent};
    transition: color 0.1s linear;

    &:hover {
      color: ${COLORS.accentHover};
    }
  }

  ul,
  ol {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    margin-bottom: 0;
  }

  ul > li + li,
  ol > li + li {
    margin-top: 0.5rem;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }

  table {
    border-collapse: collapse;
  }

  table td {
    border: 1px solid ${COLORS.darkDoctrix};
  }
`;

export const SScrollableContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  overflow-y: visible;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: revert;
    padding: revert;
    line-height: 1.4;
  }

  blockquote {
    color: ${COLORS.darkDoctrix};
    font-size: inherit;
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    margin: 16px 0;
    border-left: 5px solid #eaeaea;
    padding-left: 50px;
    background-color: #f0f1f2;

    & p {
      margin: 0;
    }
  }

  .critical {
    border-left: 5px solid #c70a0a;

    & p {
      font-weight: 700;
    }
  }


  a {
    color: ${COLORS.accent};
    transition: color 0.1s linear;

    &:hover {
      color: ${COLORS.accentHover};
    }
  }

  ul,
  ol {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    margin-bottom: 0;
  }

  ul > li + li,
  ol > li + li {
    margin-top: 0.5rem;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }

  table {
    border-collapse: collapse;
  }

  table td {
    border: 1px solid ${COLORS.darkDoctrix};
  }
`;

export const SAnchor = styled.div`
  content: "";
  display: block;
  height: 0;
  width: 0;
`;

export const SToTopButton = styled.button`
  border: none;
  position: fixed;
  width: 40px;
  height: 40px;
  right: 40px;
  bottom: 40px;
  border-radius: 10px;
  background-color: ${COLORS_WITH_OPACITY.accentLight};

  > i[data-icon-name="ChevronUpMed"] {
    font-size: 1.5rem;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    right: 16px;
    bottom: 16px;
  }
`;
