import { COLORS } from "@/constants";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const SContainer = styled.div`
  grid-area: breadcrumbs;
  display: flex;
  padding: ${() => isMobile ? '0.75rem 1rem' : '0.75rem 2.5rem'};
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.25rem;
  gap: 0.3rem;
`;

export const SNavLink = styled(NavLink) <{
  $active: boolean;
  $clickable: boolean;
}>`
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  padding: 6px 0;
  font-size: 1rem;
  height: fit-content;
  align-items: center;
  line-height: 1;
  color: #9a9a9a;
  transition: color 0.1s linear;

  ${({ $active }) => ($active ? `color: ${COLORS.darkDoctrix};` : "")}
  ${({ $clickable }) => (!$clickable ? "cursor: default;" : "")}

  &:hover {
    ${({ $clickable }) => ($clickable ? `color: ${COLORS.accentHover};` : "")}
  }

  ${() => isMobile ?  
    `& > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }`
    : ''}
`;

export const SArrowIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-left: 0.3rem;
`;
