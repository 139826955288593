import { FC, useMemo, useRef, useCallback } from "react";
import { IProps } from "./types";
import { Spinner, SpinnerSize, Icon } from "@fluentui/react";
import ReactMarkdown from "react-markdown";
import remarkMermaidPlugin from "remark-mermaid-plugin";
import { marked } from "marked";
import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import math from "remark-math";
import {
  SSpinnerContainer,
  SScrollableContainer,
  SAnchor,
  SScrollableContainerMobile,
  SToTopButton,
} from "./style";
import { isMobile } from "react-device-detect";

const MarkDownView: FC<IProps> = (props: IProps) => {
  const {
    markdownsLoaded,
    markdownData,
    isToTopButtonVisible,
    setIsToTopButtonVisible,
  } = props;

  const anchorRef = useRef<HTMLDivElement | null>(null);

  const markdownComponents = useMemo(
    () => ({
      render: () => {
        const markdown = marked.parse(markdownData as string);

        return <div dangerouslySetInnerHTML={{ __html: markdown }} />;
      },
      img: ({
        alt,
        src,
        title,
        style,
      }: {
        alt?: string;
        src?: string;
        style?: Record<string, any>,
        title?: string;
      }) => {
        return (
          <img
            alt={alt}
            src={src}
            title={title}
            style={{ ...style, maxWidth: "100%", height: "auto" }}
          />
        );
      },
    }),
    [markdownData]
  );

  const scrollToTop = useCallback(() => {
    anchorRef.current?.scrollIntoView();
  }, []);

  const ToTopButtonComponent = useMemo(() => {
    return (
      isToTopButtonVisible && (
        <SToTopButton onClick={scrollToTop}>
          <Icon iconName="ChevronUpMed" />
        </SToTopButton>
      )
    );
  }, [isToTopButtonVisible, scrollToTop]);

  if (!markdownsLoaded) {
    return (
      <SSpinnerContainer>
        <Spinner size={SpinnerSize.large} />
      </SSpinnerContainer>
    );
  }

  return isMobile ? (
    <SScrollableContainerMobile>
      <SAnchor ref={anchorRef} />
      {markdownData ? (
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[gfm, math, [remarkMermaidPlugin, { theme: "light" }]]}
          children={markdownData}
          components={markdownComponents}
          skipHtml={false}
        />
      ) : (
        <div style={{ height: "max-content", display: "block", content: "" }} />
      )}
      {ToTopButtonComponent}
    </SScrollableContainerMobile>
  ) : (
    <SScrollableContainer
      onScrollDown={() => setIsToTopButtonVisible(true)}
      onYReachStart={() => setIsToTopButtonVisible(false)}
    >
      <SAnchor ref={anchorRef} />
      {markdownData ? (
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[gfm, math, [remarkMermaidPlugin, { theme: "light" }]]}
          children={markdownData}
          components={markdownComponents}
          skipHtml={false}
        />
      ) : (
        <div style={{ height: "max-content", display: "block", content: "" }} />
      )}
      {ToTopButtonComponent}
    </SScrollableContainer>
  );
};

export { MarkDownView };
