export const COLORS = {
  white: "#FFFFFF",
  accent: "#0888FF",
  accentHover: "#007FF4",
  accentSelected: "#0078D4",
  accentLight: "#46B8FF",
  borderLight: "#DADADA",
  grey0: "#F5F7FA",
  grey1: "#E8EBF0",
  grey2: "#D8DFEB",
  icon1: "#9AA5B7",
  icon2: "#B4BDCC",
  text1: "#252628",
  text2: "#424448",
  text3: "#6D7585",
  text4: "#8992A3",
  blue: "#0888FF",
  attention: "#FFD600",
  attentionFill: "#FFFBE6",
  red: "#FF2D55",
  redHover: "#EE234A",
  redFill: "#FFF4F6",
  backgroundDoctrix: "#F3F3F3",
  darkDoctrix: "#1D1D1D",
  secondaryDoctrix: "#FFBA33",
};

export const COLORS_WITH_OPACITY = {
  accentLight: "rgba(70, 184, 255, 0.35)",
};
