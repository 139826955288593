import React, { FC, memo, useCallback, useEffect, useState } from "react";
import get from "lodash/get";
import { IProps } from "./types";
import {
  SBurgerButton,
  SMenu,
  SOuterContainer,
  SScrollableContainer,
  SScrollableContainerMobile,
} from "./styled-components";
import { isMobile } from "react-device-detect";
import { Icon } from "@fluentui/react";
import {
  Breadcrumbs,
  MarkDownView,
  NavigationBar,
  SectionBar,
} from "@/components";
import { DOMEvent } from "@/pages/types";
import { SCROLL_BUTTON_OFFSET } from "@/constants";
import { useDebounceCallback } from "@/hooks";

const ContentScreenImpl: FC<IProps> = (props) => {
  const {
    moduleVersion,
    modulesList,
    moduleNavigation,
    isUpdating,
    isToTopButtonVisible,
    setIsToTopButtonVisible,
    selectedModule,
    markdownsLoaded,
    pageData,
  } = props;

  const [isBurgerOpened, setIsBurgerOpened] = useState(false);

  const toggleBurgerMenu = useCallback(() => {
    setIsBurgerOpened((s) => !s);
  }, []);

  const handleScroll = useCallback(
    (e: DOMEvent<HTMLDivElement>) => {
      if (isMobile) {
        setIsToTopButtonVisible(e.target.scrollTop > SCROLL_BUTTON_OFFSET);
      }
    },
    [setIsToTopButtonVisible]
  );

  const onScrollDebounced = useDebounceCallback(handleScroll, 100);

  const Container: any = !isMobile
    ? SScrollableContainer
    : SScrollableContainerMobile;

  const markdownData = pageData ? get(pageData, "mdData.data") || null : null

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: 0 })
  }, [markdownData])

  return (
    <SOuterContainer
      $isBurgerOpened={isBurgerOpened}
      onScroll={isMobile ? undefined : onScrollDebounced}
    >
      <SMenu
        isOpen={isBurgerOpened}
        onClose={toggleBurgerMenu}
        customBurgerIcon={false}
        customCrossIcon={<Icon iconName="ChromeClose" />}
      >
        <NavigationBar
          modulesList={modulesList}
          selectedModule={selectedModule}
          navigation={moduleNavigation}
          isUpdating={isUpdating}
          onClick={toggleBurgerMenu}
          asBurger={true}
        />
      </SMenu>
      <NavigationBar
        modulesList={modulesList}
        navigation={moduleNavigation}
        isUpdating={isUpdating}
        selectedModule={selectedModule}
      />
      <SBurgerButton onClick={toggleBurgerMenu}>
        <Icon iconName="ChevronLeftMed" />
        <Icon iconName="NumberedListText" />
        <Icon iconName="ChevronRightMed" />
      </SBurgerButton>
      <Breadcrumbs />
      <Container>
        <MarkDownView
          markdownsLoaded={markdownsLoaded}
          markdownData={markdownData}
          isToTopButtonVisible={isToTopButtonVisible}
          setIsToTopButtonVisible={setIsToTopButtonVisible}
        />
      </Container>
      {selectedModule && (
        <SectionBar
          selectedModule={selectedModule}
          pageData={pageData}
          moduleVersion={moduleVersion}
        />
      )}
    </SOuterContainer>
  );
};

export const ContentScreen = memo(ContentScreenImpl);
