import styled from "styled-components";
import Background from "../../assets/background.png";
import { BREAKPOINTS, COLORS } from "@/constants";
import { NavLink } from "react-router-dom";

export const SContainer = styled.div`
  width: 100%;
  height: fit-content;
  background-image: url(${Background});
  background-color: ${COLORS.backgroundDoctrix};
  background-position: top right;
  background-repeat: no-repeat;
`;

export const SInnerContainer = styled.div`
  width: var(--container-width);
  margin: 0 auto;
  padding: 1.25rem 3rem;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 3.25rem;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    padding: 1rem;
    width: 100%;
  }
`;

export const SLogotype = styled.img`
  height: 4.25rem;
  width: auto;
`;

export const STopSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * + * {
    margin-top: 2rem;
  }
`;

export const STopSectionText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  color: ${COLORS.darkDoctrix};

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    font-size: 1.5rem;
  }
`;

export const SAdvantagesList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  margin-top: 2rem;

  & > * + * {
    margin-left: 2.5rem;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    flex-direction: column;
    align-items: flex-start;

    & > * + * {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }
`;

export const SAdvantage = styled.li`
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${COLORS.darkDoctrix};

  & > * + * {
    margin-left: 1rem;
  }
`;

export const SAdvantageLogo = styled.img`
  height: 2rem;
  width: auto;
`;

export const STopSectionLinks = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.75rem;
  }

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3.25rem;

    & > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
`;

export const SGotoDoctrixSiteLink = styled.a`
  padding: 1.25rem 2rem;
  border-radius: 1.75rem;
  background: ${COLORS.secondaryDoctrix};
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${COLORS.darkDoctrix};
`;

export const SDemoLink = styled.a`
  padding: 1.25rem 2rem;
  border: 1px solid ${COLORS.darkDoctrix};
  border-radius: 1.75rem;
  line-height: 1;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${COLORS.darkDoctrix};
`;

export const SModulesSection = styled.section`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 2.75rem;
  }
`;

export const SModulesHeader = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 4rem;
  color: ${COLORS.darkDoctrix};
  text-align: center;
  margin: 0 auto;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    font-size: 1.5rem;
  }
`;

export const SModulesList = styled.ul`
  list-style: none;
  width: 100%;
  margin: 0;
  margin-top: 2.75rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  row-gap: 1rem;

  @media (${BREAKPOINTS.mobileS} <= width <= ${BREAKPOINTS.mobileL}) {
    grid-template-columns: 100%;
    margin-top: 1.5rem;
  }
`;

export const SModuleCard = styled(NavLink)<{ $borderColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 0.7rem;
  padding: 1.5rem 1.5rem 4rem;
  border-bottom: 40px solid
    ${({ $borderColor }) => $borderColor || COLORS.grey1};
  background: ${COLORS.white};
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1.5rem;
  }
`;

export const SModuleContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SModuleCardTitle = styled.h3`
  font-weight: 400;
  font-size: 1.75rem;
`;

export const SModuleCardSubTitle = styled.h4`
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
`;

export const SModuleCardDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

export const SModuleCardHeader = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 1rem;
  }
`;

export const SModuleCardLogotype = styled.img`
  width: 60px;
  height: 60px;
`;

export const SGrantText = styled.span`
  font-size: 1rem;
  color: #9a9a9a;
`;
